import gear from "./gear.svg";
import "./App.css";
import logo from "./logo_rounded.png";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img className="App-logo" src={logo} alt="" />
        <h3 style={{ paddingBottom: "3%" }}>SITE UNDER CONSTRUCTION</h3>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ color: "gray", paddingRight: 20 }}>
            Please visit us later
          </p>
          <img src={gear} className="App-gear" alt="" />
        </div>

        <a
          className="App-link"
          href="mailto:info@thermopylaecapital.com"
          rel="noopener noreferrer"
        >
          info@thermopylaecapital.com
        </a>
      </header>
    </div>
  );
}

export default App;
